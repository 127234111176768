<template>
    <section class="op-calendario container-fluid w-100 overflow-auto custom-scroll mt-2">
        <titulo-divisor titulo="Tareas">
            <div class="row">
                <div class="col-auto my-auto px-1">
                    <button class="btn btn-general f-12 px-5" @click="crearTarea">
                        Crear tarea
                    </button>
                </div>
            </div>
        </titulo-divisor>
         <div class="row mx-0 my-4 justify-content-end">
            <div class="col-4 text-center d-none">
                <h3 class="text-general f-700">2021</h3>
            </div>
            <div class="col-4 d-middle my-auto router-buttons justify-content-end f-15">
                <router-link :to="{ name:'oportunidades.ver.bitacora.calendario' }" class="br-l-4 ">
                    Año
                </router-link>
                <router-link :to="{ name:'oportunidades.ver.bitacora.mes' }" class="">
                    Mes
                </router-link>
                <router-link :to="{ name:'oportunidades.ver.bitacora.semana' }" class="">
                    Semana
                </router-link>
                <router-link :to="{ name:'oportunidades.ver.bitacora.lista' }" class="br-r-4 ">
                    Lista
                </router-link>
            </div>
        </div>
        <div class="row mx-0">
            <div class="col">
                <router-view />
            </div>
        </div>
        
        <!-- partials -->
        <modal-crear-tarea ref="modalCrearTarea" />
    </section>
</template>
<script>

export default {
    components:{
        modalCrearTarea: () => import('./partials/modalCrearTarea.vue'),
    },
    data(){
        return{
        }
    },
    methods:{
        crearTarea(){
            this.$refs.modalCrearTarea.toggle()
        },
        abrirModalInfo(){
            console.log('ok');
        }
    }
}
</script>
<style lang="scss" scoped>
.op-calendario{
    height: calc(100vh - 240px);
    .router-buttons{
        a{
            background: #DDDDDD;
            color: #5D5D5D;
            font-size: 12px;
            padding: 8px;
            &.router-link-exact-active{
                background: var(--color-general);
                color: #fff;
            }
            &:hover{
                text-decoration: none;
                background: var(--color-general);
                color: #fff;
            }
        }
    }
    
}
</style>